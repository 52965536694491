// Entry point for the build script in your package.json

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require('jquery')

import "bootstrap"

// JS
import "./application/clickable_row"

// Stimulus
import "./controllers"
