import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';
import 'select2';

export default class extends Controller {
  static targets = ["category", "pricetype", "price", "quantity", "calculated_price", "saved_unit_price", "new_price"]

  async connect() {
    // initial update
    let selectField = this.element.querySelectorAll(".offer_position select:not(.btn)")[0]
    await this.updateProductViaAjax(selectField)
    let quantity = this.element.querySelectorAll(".quantity_input")[0].value
    this.calculatePrice(quantity)
  }

  selection(){
    this.updateProductViaAjax(event.currentTarget)
  }

  useNewPrice() {
    let price = this.new_priceTarget.getAttribute('data-value');
    this.updatePrice(price);
    this.calculatePrice(this.quantityTarget.value)
  }

  inputQuantity(){
    this.calculatePrice(this.quantityTarget.value)
  }

  updateProductViaAjax(selector) {
    return new Promise(resolve => {
      let that = this
      let id = selector.value
      let url = `/product/${id}`
      $.get( url, function(data) {
        that.updateCategory(data.category.name);
        that.updatePriceType(data.price_type.name)
        that.updatePrice(data.product.price, data.product.id)
        that.calculatePrice(that.quantityTarget.value)
        resolve();
      });
    })
  }

  updateCategory(category) {
    this.categoryTarget.innerHTML = category
  }

  updatePriceType(price_type) {
    this.pricetypeTarget.innerHTML = price_type
  }

  updatePrice(price, product_id = null) {
    let savedProductId = this.saved_unit_priceTarget.getAttribute("data-id")
    let savedUnitPrice = this.saved_unit_priceTarget.getAttribute("data-value")
    // changed price? Use saved_price with hint
    if (savedUnitPrice && product_id == savedProductId && price != savedUnitPrice) {
      this.priceTarget.innerHTML = savedUnitPrice
      this.priceTarget.setAttribute("data-value", savedUnitPrice)
      this.new_priceTarget.innerHTML = `Neuer Preis: ${price} <span class="price_per_unit_new__btn" data-action="input->position-details#useNewPrice"><i class="fas fa-sync"></i> Änderung übernehmen</span>`
      this.new_priceTarget.setAttribute("data-value", price)
    } else {
      // reset new price hint
      this.new_priceTarget.innerHTML = ""
      this.saved_unit_priceTarget.setAttribute("data-value", price)
      this.saved_unit_priceTarget.value = price

      this.priceTarget.innerHTML = price
      this.priceTarget.setAttribute("data-value", price)
    }
  }

  calculatePrice(quantity) {
    let priceFloat = parseFloat(this.priceTarget.getAttribute("data-value"))
    let quantityFloat = parseFloat(quantity) || 1
    this.calculated_priceTarget.value = quantityFloat * priceFloat;
    let event = new Event('total', { bubbles: true })
    this.element.dispatchEvent(event)
  }
}
