import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';
import 'select2';

export default class extends Controller {
  static targets = ["calculated_price", "total_price"]

  connect() {
    this.recalculate_total
  }

  recalculate_total() {
    let positionPrices = this.calculated_priceTargets
    let total = 0;

    positionPrices.forEach( price => {
      total += parseFloat(price.value);
    })

    this.total_priceTarget.innerHTML = `CHF ${total}`
  }
}
