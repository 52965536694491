import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: '.move',
      animation: 150,
      onEnd: this.end.bind(this)
    })
    this.update_positions()
  }

  end(event){
    this.update_positions()
  }

  update_positions(){
    let positions = document.querySelectorAll(".field_position")
    let index = 0

    positions.forEach( pos => {
      index += 1
      pos.value = index
    })
  }
}
