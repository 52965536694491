import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';
import 'select2';

export default class extends Controller {
  connect() {
    this.createSelect2()
  }

  init(event) {
    let that = this
    // timeout to make sure new offer_position has been created
    setTimeout(function() {
      that.createSelect2()
    }, 100);
  }

  createSelect2() {
    let element = this.element

    $(element).select2()
    .on('select2:select', function() {
      let event = new Event('change', { bubbles: true })
      element.dispatchEvent(event)
    });
  }
}
